import React from "react"
import {graphql} from "gatsby"

import Layout from "../layout";
import SEO from "../components/SEO/SEO";
import trooper from "../../static/images/james-pond-riEYPSKxoTw-unsplash.jpg";

const NotFoundPage = ({data, location}) => {
    const siteTitle = data.site.siteMetadata.title

    return (
        <Layout location={location} title={siteTitle}>
            <SEO title="404: Not Found"/>
            <h1>Not Found</h1>
            <img src={trooper} className="rounded img-thumbnail mx-auto d-block"/>
            <blockquote className="blockquote">
                <p className="mb-0">This is not the page you are looking for....</p>
                <footer className="blockquote-footer">The Internet</footer>
            </blockquote>
        </Layout>
    )
}

export default NotFoundPage

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`
